import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, NotificationService } from 'mnm-webapp';
import { finalize, map } from 'rxjs/operators';
import { Item, LibraryFile } from '@masar/common/models';
import { MnmFormField, MnmFormState } from '@masar/shared/components';
import { fields } from './fields';
import { NewFileDialogService } from './new-file-dialog.service';
import { Subscription } from 'rxjs';
import { LibraryTagService } from './library-tag.service';
import { Loader } from '@masar/common/misc/loader';
import { LibraryFileScopes, OrganizationOrigin } from '@masar/common/enums';
import { AppSettingFetcherService } from '@masar/core/services';
import { isOrigin } from '@masar/common/utils';

@Component({
    selector: 'app-new-file-dialog',
    templateUrl: './new-file-dialog.component.html',
    providers: [NewFileDialogService, LibraryTagService],
})
export class NewFileDialogComponent implements OnInit, OnDestroy {
    @Input() public mode: 'new' | 'edit' = 'new';
    @Input() public libraryFileId: string;
    @Input() public scopes?: string[];
    @Input() public showIsSharedField?: boolean;

    @Output()
    public fileCreated: EventEmitter<LibraryFile> =
        new EventEmitter<LibraryFile>();

    @Output()
    public fileUpdated: EventEmitter<LibraryFile> =
        new EventEmitter<LibraryFile>();

    public formState: MnmFormState;
    public isSubmitting = false;

    private readonly tagsLoader: Loader<Item>;

    public constructor(
        private readonly newFileDialogService: NewFileDialogService,
        private readonly notificationService: NotificationService,
        private readonly libraryTagService: LibraryTagService,
        private readonly translateService: TranslateService,
        appSettingFetcherService: AppSettingFetcherService,
        fb: FormBuilder
    ) {
        this.formState = new MnmFormState(fields(appSettingFetcherService), fb);

        this.tagsLoader = new Loader<Item>(
            keyword => {
                const selectedIds: string[] = this.formState.group
                    .get('tags')
                    .value?.map(item => item.id as string);

                return libraryTagService.list(keyword, selectedIds, 0, 3).pipe(
                    map(tags =>
                        tags.items.map(tag => ({
                            id: tag.id,
                            name: tag.name,
                        }))
                    )
                );
            },
            [],
            this.libraryTagService.create.bind(this.libraryTagService)
        );

        this.formState.get('tags').loader = this.tagsLoader;
    }

    public static async showAsCreateDialog(
        modalService: ModalService,
        translateService: TranslateService,
        callback: (newFile: LibraryFile) => void,
        scopes?: string[],
        showIsSharedField?: boolean
    ): Promise<void> {
        let subscription: Subscription;

        const component = await modalService.show(NewFileDialogComponent, {
            onDismiss: () => subscription.unsubscribe(),
            title: translateService.instant('translate_add_new_attachment'),
            beforeInit: c => {
                c.mode = 'new';
                c.scopes = scopes;
                c.showIsSharedField = showIsSharedField;
            },
        });

        subscription = component.fileCreated.subscribe(file => {
            callback(file);
            modalService.dismiss(component);
        });
    }

    public static async showAsUpdateDialog(
        modalService: ModalService,
        translateService: TranslateService,
        libraryFileId: string,
        callback: (newFile: LibraryFile) => void
    ): Promise<void> {
        let subscription: Subscription;

        const component = await modalService.show(NewFileDialogComponent, {
            onDismiss: () => subscription.unsubscribe(),
            title: translateService.instant('translate_update_attachment'),
            beforeInit: c => {
                c.mode = 'edit';
                c.libraryFileId = libraryFileId;
            },
        });

        subscription = component.fileUpdated.subscribe(file => {
            callback(file);
            modalService.dismiss(component);
        });
    }

    public ngOnInit(): void {
        switch (this.mode) {
            case 'edit':
                this.newFileDialogService
                    .get(this.libraryFileId, true)
                    .subscribe(item => this.fillForm(item));
                break;
            case 'new':
                this.formState.group.get('scopes').setValue(this.scopes);

                if (this.showIsSharedField) {
                    const isSharedField: MnmFormField = {
                        name: 'isShared',
                        label: 'translate_add_to_library',
                        type: 'checkbox',
                        hide: isOrigin([
                            OrganizationOrigin.injaz,
                            OrganizationOrigin.police,
                        ]),
                    };

                    this.formState.addField(isSharedField, 7);

                    this.formState.group
                        .get('isShared')
                        .valueChanges.subscribe(value => {
                            const scopes =
                                this.formState.group.get('scopes').value;

                            const index = scopes.indexOf(
                                LibraryFileScopes.shared
                            );
                            if (index !== -1) scopes.splice(index, 1);

                            if (value) scopes.push(LibraryFileScopes.shared);

                            this.formState.group.get('scopes').setValue(scopes);
                        });
                }

                break;
        }
    }

    public ngOnDestroy(): void {
        this.tagsLoader.dispose();
    }

    public reset(): void {
        this.formState.reset();
        this.libraryFileId = '';
    }

    public submit(): boolean | Promise<boolean> {
        this.formState.setTriedToSubmit();

        if (this.formState.group.invalid) {
            return false;
        }

        this.isSubmitting = true;

        const observable =
            this.mode === 'new'
                ? this.newFileDialogService.create(
                      this.formState.group.getRawValue()
                  )
                : this.newFileDialogService.update(
                      this.formState.group.getRawValue()
                  );

        return observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .toPromise()
            .then(item => {
                this.translateService
                    .get('translate_item_added_successfully')
                    .subscribe(str => {
                        this.notificationService.notifySuccess(str);
                    });

                if (this.mode === 'new') {
                    this.fileCreated.emit(item);
                } else if (this.mode === 'edit') {
                    this.fileUpdated.emit(item);
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    private fillForm(item: LibraryFile): void {
        for (const key of Object.keys(this.formState.group.controls)) {
            this.formState.group.controls[key].setValue(item[key]);
        }
    }
}
