<app-content contentTitle="{{ 'translate_attachments' | translate }}">
    <!-- Tools -->
    <ng-container tools>
        <ng-container
            *appIsNotOrigin="[
                organizationOrigin.injaz,
                organizationOrigin.police
            ]"
        >
            <ng-container *appHasPermissionId="requiredPermissionForLinking">
                <button
                    *ngIf="mode === 'default'"
                    (click)="showLibraryDialog()"
                    class="btn btn-sm btn-outline-white me-2"
                >
                    <i class="fa-light fa-plus"></i>
                    <span>{{ 'translate_add_from_library' | translate }}</span>
                </button>
            </ng-container>
        </ng-container>

        <ng-container *appHasPermissionId="requiredPermissionForLinking">
            <button
                *ngIf="mode === 'default'"
                (click)="showCreateLibraryFileDialog()"
                class="btn btn-sm btn-outline-white"
            >
                <i class="fa-light fa-plus"></i>
                <span> {{ 'translate_new_attachment' | translate }}</span>
            </button>
        </ng-container>
    </ng-container>

    <!-- Content -->
    <app-library-file-list-full
        content
        [shownFilters]="['name']"
    ></app-library-file-list-full>
</app-content>

<!-- Template for select button -->
<ng-template let-item="item" #selectButtonTemplate>
    <button
        (click)="linkLibraryFile(item)"
        [disabled]="currentlyProcessing.has(item.id)"
        class="btn btn-sm btn-info"
    >
        <i class="fa fa-check"></i>
    </button>
</ng-template>

<!-- Template for the unlinking button -->
<ng-template #controlColumnTemplateRef let-item="item">
    <button
        (confirm)="unlinkLibraryFile(item)"
        *appHasPermissionId="requiredPermissionForUnlinking"
        [disabled]="currentlyProcessing.has(item.id)"
        [swal]="{
            title: 'translate_delete_this_item_question_mark' | translate,
            confirmButtonText: 'translate_yes' | translate,
            cancelButtonText: 'translate_cancel' | translate,
            showCancelButton: true,
            showCloseButton: true
        }"
        class="btn btn-sm btn-danger"
    >
        <i class="fas fa-trash"></i>
    </button>
</ng-template>
