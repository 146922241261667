import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService, NotificationService } from 'mnm-webapp';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { TableResult } from '@masar/common/misc/table';
import { LibraryFile } from '@masar/common/models';
import { NewFileDialogComponent } from '../new-file-dialog/new-file-dialog.component';
import { LibraryFileListFullComponent } from '../library-file-list-full/library-file-list-full.component';
import { PermissionValue } from '@masar/common/types';
import { OrganizationOrigin } from '@masar/common/enums';

@Component({
    selector: 'app-library-file-linker',
    templateUrl: './library-file-linker.component.html',
})
export class LibraryFileLinkerComponent implements AfterViewInit, OnChanges {
    @Input() public mode: 'default' | 'view' = 'default';
    @Input() public listLinkedFilesCallback: (
        keyword: string,
        pageNumber: number,
        pageSize: number
    ) => Observable<TableResult<LibraryFile>>;
    @Input() public listUnlinkedLibraryFilesCallback?: (
        keyword: string,
        pageNumber: number,
        pageSize: number
    ) => Observable<TableResult<LibraryFile>>;
    @Input() public linkingCallback: (fileId: string) => Observable<string>;
    @Input() public unlinkingCallback: (fileId: string) => Observable<string>;

    @Input() public requiredPermissionForLinking: PermissionValue;
    @Input() public requiredPermissionForUnlinking: PermissionValue;

    @Input() public scopes?: string[];

    @Input() public showIsSharedField?: boolean;

    @ViewChild('selectButtonTemplate')
    private selectButtonTemplate: TemplateRef<any>;

    @ViewChild('controlColumnTemplateRef')
    private controlColumnTemplateRef: TemplateRef<any>;

    @ViewChild(LibraryFileListFullComponent)
    private list: LibraryFileListFullComponent;

    public currentlyProcessing = new Set<string>();

    public readonly organizationOrigin = OrganizationOrigin;
    private unlinkedListComponentModal?: LibraryFileListFullComponent;

    public constructor(
        private notificationService: NotificationService,
        private modalService: ModalService,
        private readonly translateService: TranslateService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    public ngOnChanges(): void {
        this.refreshCustomFields();
    }

    public ngAfterViewInit(): void {
        this.list.alternateListCallback = filter => {
            return this.listLinkedFilesCallback(
                filter.data.keyword,
                filter.pageNumber,
                filter.pageSize
            );
        };

        this.refreshCustomFields();

        // Changing input programmatically does not
        // trigger ng on changes, should manually
        // call it.
        this.list.ngOnChanges({
            alternateListCallback: {
                previousValue: undefined,
                currentValue: this.list.alternateListCallback,
            } as any,
        });
        this.changeDetectorRef.detectChanges();
    }

    public linkLibraryFile(item: LibraryFile): void {
        this.currentlyProcessing.add(item.id);

        this.linkingCallback(item.id)
            .pipe(finalize(() => this.currentlyProcessing.delete(item.id)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);

                this.unlinkedListComponentModal?.refreshItems();

                this.list.refreshItems();
            });
    }

    public unlinkLibraryFile(item: LibraryFile): void {
        this.currentlyProcessing.add(item.id);

        this.unlinkingCallback(item.id)
            .pipe(finalize(() => this.currentlyProcessing.delete(item.id)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.list.refreshItems();
            });
    }

    public async showLibraryDialog(): Promise<void> {
        this.unlinkedListComponentModal = await this.modalService.show(
            LibraryFileListFullComponent,
            {
                size: { width: '70%' },
                beforeInit: c => {
                    c.shownFilters = ['name', 'tag'];

                    if (this.listUnlinkedLibraryFilesCallback) {
                        c.alternateListCallback = filter => {
                            return this.listUnlinkedLibraryFilesCallback(
                                filter.data.keyword,
                                filter.pageNumber,
                                filter.pageSize
                            );
                        };
                    }
                },
                onDismiss: () => {
                    this.unlinkedListComponentModal = null;
                },
            }
        );

        this.unlinkedListComponentModal.list.customFields = [
            {
                name: '',
                template: this.selectButtonTemplate,
            },
        ];
    }

    public async showCreateLibraryFileDialog(): Promise<void> {
        await NewFileDialogComponent.showAsCreateDialog(
            this.modalService,
            this.translateService,
            file => this.linkLibraryFile(file),
            this.scopes,
            this.showIsSharedField
        );
    }

    private refreshCustomFields(): void {
        if (!this.list?.list) return;

        if (this.mode === 'default') {
            this.list.list.customFields = [
                {
                    name: '',
                    template: this.controlColumnTemplateRef,
                },
            ];
        } else this.list.list.customFields = [];
    }
}
