import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Result } from 'mnm-webapp';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { environment } from '@masar/env/environment';
import { messageTypeList } from '@masar/common/misc/message-type-list';
import { SignalrService } from './signalr.service';

interface UnreadCounts {
    totalUnread: number;
    newUserRequestUnread: number;
}
@Injectable()
export class NotificationUnreadCountService implements OnDestroy {
    private subject = new BehaviorSubject<UnreadCounts>({
        totalUnread: 0,
        newUserRequestUnread: 0,
    });
    private unsubscribeAll = new Subject();

    public constructor(
        private httpClient: HttpClient,
        signalrService: SignalrService
    ) {
        this.update();

        signalrService
            .messages()
            .pipe(
                takeUntil(this.unsubscribeAll),
                filter(x => x.type === messageTypeList.notificationCountUpdate)
            )
            .subscribe(() => this.update());
    }

    // Keep the original count$ for backward compatibility
    public get count$(): Observable<number> {
        return this.subject.pipe(map(counts => counts.totalUnread));
    }

    // Add new observable for new user requests
    public get newUserRequestUnread$(): Observable<number> {
        return this.subject.pipe(map(counts => counts.newUserRequestUnread));
    }

    public get count(): number {
        return this.subject.value.totalUnread;
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
        this.subject.complete();
    }

    private update(): void {
        this.httpClient
            .get<Result<UnreadCounts>>(
                `${environment.apiUrl}/notification/user/unread-count`
            )
            .subscribe(res => this.subject.next(res.extra));
    }
}
