import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
    KpiResultBreakdown,
    KpiResultPeriodBreakdown,
    KpiResultPeriodBreakdownValue,
} from '@masar/common/models';
import { environment } from '@masar/env/environment';
import { miscFunctions, mnmHttpInterceptorParams, Result } from 'mnm-webapp';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class KpiResultBreakdownService {
    public constructor(private httpClient: HttpClient) {}

    public list(resultId: string): Observable<KpiResultBreakdown[]> {
        return this.httpClient
            .get<Result<KpiResultBreakdown[]>>(
                `${environment.apiUrl}/kpi-result/${resultId}/breakdown`
            )
            .pipe(map(res => res.extra));
    }

    public get(
        resultId: string,
        categoryId: string,
        parameter: 'a' | 'b'
    ): Observable<KpiResultBreakdown> {
        return this.httpClient
            .get<Result<KpiResultBreakdown>>(
                `${environment.apiUrl}/kpi-result/${resultId}/breakdown/${categoryId}/${parameter}`
            )
            .pipe(map(res => res.extra));
    }

    public createOrUpdate(
        resultId: string,
        breakdown: KpiResultBreakdown
    ): Observable<string> {
        return this.httpClient
            .post<Result>(
                `${environment.apiUrl}/kpi-result/${resultId}/breakdown`,
                miscFunctions.objectToURLParams({
                    breakdown: JSON.stringify(breakdown),
                })
            )
            .pipe(map(res => res.messages[0]));
    }

    public delete(
        resultId: string,
        categoryId: string,
        parameter: 'a' | 'b'
    ): Observable<string> {
        return this.httpClient
            .delete<Result>(
                `${environment.apiUrl}/kpi-result/${resultId}/breakdown/${categoryId}/${parameter}`
            )
            .pipe(map(res => res.messages[0]));
    }

    public listPeriodBreakdown(
        periodId: string
    ): Observable<KpiResultPeriodBreakdown[]> {
        return this.httpClient
            .get<Result<KpiResultPeriodBreakdown[]>>(
                `${environment.apiUrl}/kpi-result/period/${periodId}/breakdown`
            )
            .pipe(map(res => res.extra));
    }

    public getPeriodBreakdown(
        periodId: string,
        categoryId: string,
        parameter: 'a' | 'b'
    ): Observable<KpiResultPeriodBreakdown> {
        return this.httpClient
            .get<Result<KpiResultPeriodBreakdown>>(
                `${environment.apiUrl}/kpi-result/period/${periodId}/breakdown/${categoryId}/${parameter}`
            )
            .pipe(map(res => res.extra));
    }

    public getPreviousPeriodBreakdown(
        periodId: string,
        categoryId: string,
        parameter: 'a' | 'b'
    ): Observable<KpiResultPeriodBreakdown> {
        return this.httpClient
            .get<Result<KpiResultPeriodBreakdown>>(
                `${environment.apiUrl}/kpi-result/period/previous/${periodId}/breakdown/${categoryId}/${parameter}`
            )
            .pipe(map(res => res.extra));
    }

    public updatePeriodBreakdown(
        periodId: string,
        categoryId: string,
        parameter: 'a' | 'b',
        values: KpiResultPeriodBreakdownValue[]
    ): Observable<string> {
        return this.httpClient
            .put<Result>(
                `${environment.apiUrl}/kpi-result/period/${periodId}/breakdown/${categoryId}/${parameter}`,
                miscFunctions.objectToURLParams({
                    breakdown: JSON.stringify({ values }),
                })
            )
            .pipe(map(res => res.messages[0]));
    }

    public export(resultId: string): Observable<Blob> {
        return this.httpClient.get(
            `${environment.apiUrl}/kpi-result/${resultId}/breakdown/export`,
            {
                responseType: 'blob',
                params: new HttpParams().append(
                    mnmHttpInterceptorParams.stealth,
                    'true'
                ),
            }
        );
    }
}
