import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    KpiResultPeriod,
    KpiResultPeriodBreakdown,
} from '@masar/common/models';
import { finalize } from 'rxjs/operators';
import { KpiResultBreakdownService } from '../../../kpi-result-breakdown.service';
import { NotificationService } from 'mnm-webapp';

@Component({
    selector: 'app-period-breakdown-update',
    templateUrl: './period-breakdown-update.component.html',
})
export class PeriodBreakdownUpdateComponent implements OnInit {
    @Input() public period: KpiResultPeriod;

    @Input() public categoryId: string;

    @Input() public parameter: 'a' | 'b';

    @Input() public totalPeriods: number;

    @Output() public update = new EventEmitter();

    public searchText?: string;

    public previousPeriodBreakdown: KpiResultPeriodBreakdown;
    public item: KpiResultPeriodBreakdown;

    public actualValue: number = null;

    public aggregatedValue: number = null;

    public isSubmitting = false;

    public constructor(
        private kpiResultBreakdownService: KpiResultBreakdownService,
        private notificationService: NotificationService
    ) {}

    public ngOnInit(): void {
        this.actualValue =
            this.parameter === 'a' ? this.period.a : this.period.b;

        // Get current breakdown
        this.kpiResultBreakdownService
            .getPeriodBreakdown(this.period.id, this.categoryId, this.parameter)
            .subscribe(item => {
                this.item = item;
                this.recalculateAggregatedValue();
                // After getting current, get previous period
                if (this.period.period > 0) {
                    // Only if not first period
                    // We need to get previous period ID first
                    this.getPreviousPeriodBreakdown();
                }
            });
    }

    public getPreviousValue(subcategoryId: string): number | null {
        if (!this.previousPeriodBreakdown) return null;

        const prevValue = this.previousPeriodBreakdown.values.find(
            v => v.subcategory.id === subcategoryId
        );
        return prevValue?.value ?? null;
    }

    public submit(): void {
        this.isSubmitting = true;

        const observable = this.kpiResultBreakdownService.updatePeriodBreakdown(
            this.period.id,
            this.categoryId,
            this.parameter,
            this.item.values
        );

        observable
            .pipe(finalize(() => (this.isSubmitting = false)))
            .subscribe(message => {
                this.notificationService.notifySuccess(message);
                this.update.emit();
            });
    }

    public recalculateAggregatedValue(): void {
        this.aggregatedValue = this.item.values.every(
            x => x.value === null || x.value === undefined
        )
            ? null
            : this.item.values.reduce((a, b) => a + b.value, 0);
    }

    private getPreviousPeriodBreakdown(): void {
        // First get all periods from the KPI result
        const currentPeriodIndex = this.period.period;

        // Find the previous period
        if (currentPeriodIndex > 0) {
            if (this.period.id) {
                this.loadPreviousPeriodData(this.period.id);
            }
        }
    }

    private loadPreviousPeriodData(previousPeriodId: string): void {
        this.kpiResultBreakdownService
            .getPreviousPeriodBreakdown(
                previousPeriodId,
                this.categoryId,
                this.parameter
            )
            .subscribe(
                prevItem => {
                    this.previousPeriodBreakdown = prevItem;
                },
                error => {
                    console.error('Error loading previous period data:', error);
                }
            );
    }
}
