<app-list-loading [items]="item?.values">
    <!-- Category name & parameter -->
    <div class="mb-5 flex flex-row items-center gap-2">
        <!-- Category name -->
        <div class="rounded bg-gray-100 px-3 py-2">
            {{ item?.category.name }}
        </div>

        <!-- Parameter -->
        <div class="rounded bg-gray-100 px-3 py-2">
            {{ item?.parameter.toUpperCase() }}
        </div>

        <input
            class="flex-grow"
            [placeholder]="'translate_search_by_name' | translate"
            [(ngModel)]="searchText"
            aria-label="Search"
        />
    </div>

    <div class="mb-5 flex flex-row items-center justify-center gap-2">
        <!-- Aggregated value -->
        <div
            class="flex flex-col items-center justify-center gap-1 rounded border px-3 py-2"
            [ngClass]="{
                'border-red-800 bg-red-100 text-red-800':
                    aggregatedValue !== null &&
                    actualValue !== null &&
                    aggregatedValue !== actualValue,
                'border-green-800 bg-green-100 text-green-800':
                    aggregatedValue !== null &&
                    actualValue !== null &&
                    aggregatedValue === actualValue,
                'border-gray-800 bg-gray-100':
                    aggregatedValue === null || actualValue === null
            }"
        >
            <span>{{ 'translate_aggregated_value' | translate }}</span>
            <span class="text-xl font-bold">{{
                aggregatedValue ?? 'N/A'
            }}</span>
        </div>

        <!-- Actual value -->
        <div
            class="flex flex-col items-center justify-center gap-1 rounded border border-gray-800 bg-gray-100 px-3 py-2"
        >
            <span>{{ 'translate_actual_value' | translate }}</span>
            <span class="text-xl font-bold">{{ actualValue ?? 'N/A' }}</span>
        </div>
    </div>

    <!-- Items -->
    <div class="mb-5">
        <!-- Period Labels -->
        <div class="mb-2 grid grid-cols-3 items-center gap-2">
            <span></span>
            <span class="text-center font-bold">
                <ng-container [ngSwitch]="item?.measurementCycle">
                    <ng-container *ngSwitchCase="'annual'">
                        {{ 'translate_annual' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'semi_annual'">
                        {{ 'translate_year_half' | translate }}
                        {{ item?.period + 1 }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'quarter'">
                        {{ 'translate_quarter' | translate }}
                        {{ item?.period + 1 }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'month'">
                        {{ 'translate_month' | translate }}
                        {{ item?.period + 1 }}
                    </ng-container>
                </ng-container>
            </span>
            <span class="text-center font-bold" *ngIf="item?.period > 0">
                <ng-container [ngSwitch]="item?.measurementCycle">
                    <ng-container *ngSwitchCase="'semi_annual'">
                        {{ 'translate_year_half' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'quarter'">
                        {{ 'translate_quarter' | translate }}
                    </ng-container>
                    <ng-container *ngSwitchCase="'month'">
                        {{ 'translate_month' | translate }}
                    </ng-container>
                </ng-container>
                {{ item?.period }}
            </span>
        </div>

        <!-- Values Grid -->
        <div class="grid grid-cols-3 items-center gap-2">
            <ng-container
                *ngFor="
                    let value of item?.values
                        | filter : searchText : 'subcategory.name'
                "
            >
                <span>{{ value.subcategory.name }}</span>

                <!-- Current Period Column (Editable Input) -->
                <input
                    (keyup)="recalculateAggregatedValue()"
                    type="number"
                    [(ngModel)]="value.value"
                />
                <!-- Previous Period Column (Disabled Input) -->
                <div [class.col-span-1]="true">
                    <input
                        type="number"
                        [value]="getPreviousValue(value.subcategory.id)"
                        disabled
                        class="cursor-not-allowed bg-gray-50"
                        *ngIf="item?.period > 0"
                    />
                    <!-- Placeholder div to maintain grid structure when input is hidden -->
                    <div *ngIf="item?.period > 0" class="h-full"></div>
                </div>
                <!-- Name Column -->
            </ng-container>
        </div>
    </div>
    <!-- Submit button -->
    <div class="text-center">
        <button
            class="btn btn-primary inline-flex items-center gap-2"
            (click)="submit()"
            [disabled]="isSubmitting"
        >
            <app-loading-ring
                *ngIf="isSubmitting"
                class="me-2"
            ></app-loading-ring>
            <i class="fa-light fa-save me-2"></i>
            <span>{{ 'translate_save' | translate }}</span>
        </button>
    </div>
</app-list-loading>
