import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-go-back-button',
    template: `
        <button
            dir="ltr"
            [routerLink]="computedRoute"
            [appTooltip]="computedLabel | translate"
            class="btn btn-sm btn-white flex items-center gap-1"
        >
            <i class="fas fa-arrow-left me-2"></i>
            <span class="hidden md:inline">
                {{ computedLabel | translate }}
            </span>
        </button>
    `,
})
export class GoBackButtonComponent implements OnInit {
    @Input() public targetRoute?: string;

    @Input() public label?: string;
    @Input() public link?: any[];

    public computedRoute: string[] = ['/'];
    public computedLabel: string = 'translate_back';

    public constructor(public router: Router) {}

    public ngOnInit(): void {
        this.initializeBackNavigation();
    }

    public initializeBackNavigation(): void {
        // Handle existing targetRoute input
        if (this.targetRoute) {
            this.computedRoute = ['/', this.targetRoute];
            this.computedLabel = `translate_back`;
            return;
        }

        // Handle new link/label pattern
        if (this.link) {
            this.computedRoute = this.link;
        } else {
            const urlSegments = this.router.url
                .split('/')
                .filter(segment => segment);
            if (urlSegments.length > 0) {
                this.computedRoute = ['/', urlSegments[0]];
            }
        }

        if (this.label) {
            this.computedLabel = `translate_${this.label}`;
        } else {
            this.computedLabel = `translate_back`;
        }
    }
}
